// @ts-nocheck
import * as R from "ramda"

import { capitalizeFirstLetter } from "../../../../../../utils"
import { newFormatNumber } from "../../../../../_shared"
import { cellClass } from "./classRulesMsc"
import { getColTitle } from "./getTitle"

export * from "./classRulesMsc"
export * from "./contextMenuMsc"
export * from "./forecastList"
export * from "./getNiceFormulaItem"
export * from "./getRange"
export * from "./getFormulaItems"
export * from "./getTitle"
export * from "./stockFields"
export * from "./testMscData"
export * from "./yupSchemas"

// get flatRowDefs from rows of rowDefs, extract stockRows and flatten it
export const shapeRows = rows => {
  const rowIdWithStrName = (rowId, str_name) => rowId + "#" + str_name
  return R.compose(
    R.flatten,
    R.map(row =>
      row.kind === "stockRows"
        ? R.map(
            stock =>
              R.compose(
                R.assoc("kind", row.kind),
                R.assoc("rowId", rowIdWithStrName(row.rowId, stock.str_name)),
              )(stock),
            row.stocks,
          )
        : row,
    ),
  )(rows)
}

// get title for aggregate row: by default it's the method, unless there is a titleOverride
const aggregateTitle = (rowData, flatRowDefs, row) =>
  R.find(R.pathEq(["rowId"], row.extra.rowId), flatRowDefs).frontOpts.titleOverride
    ? R.find(R.pathEq(["rowId"], row.extra.rowId), flatRowDefs).frontOpts.titleOverride
    : capitalizeFirstLetter(R.find(R.pathEq(["rowId"], row.extra.rowId), flatRowDefs).method)

// get title for stock Rows : by default it's the bloomberg code, unless there is a titleOverride
const stockRowTitle = (rowData, flatRowDefs, row) =>
  R.find(R.pathEq(["rowId"], row.extra.rowId), flatRowDefs).frontOpts.titleOverride
    ? R.find(R.pathEq(["rowId"], row.extra.rowId), flatRowDefs).frontOpts.titleOverride
    : R.find(R.pathEq(["rowId"], row.extra.rowId), flatRowDefs).str_name

// format rowData for aggrid (rowId is used for relation between rowData & flatRowDefs)
export const shapeRowData = (rowData, flatRowDefs) => {
  return [
    ...R.map(
      row =>
        R.compose(
          R.assoc(
            "desc",
            R.find(R.pathEq(["rowId"], row.extra.rowId), flatRowDefs).kind === "aggregate"
              ? aggregateTitle(rowData, flatRowDefs, row)
              : R.find(R.pathEq(["rowId"], row.extra.rowId), flatRowDefs).kind === "stockRows"
                ? stockRowTitle(rowData, flatRowDefs, row)
                : R.find(R.pathEq(["rowId"], row.extra.rowId), flatRowDefs).frontOpts.title,
            // : R.path(["extra", "desc"], row),
          ),
          R.mapObjIndexed((el, key, object) => {
            if (key === "extra")
              return R.assoc(
                "frontOpts",
                R.find(R.pathEq(["rowId"], el.rowId), flatRowDefs).frontOpts, // pick frontOpts in rowDefs
                el,
              )
            return R.propOr("", "value", el)
          }),
        )(row),
      rowData,
    ),
  ]
}

// Background color for heatmap
const getBackground = ({ value, min, max, avg, heatmap }) => {
  if (heatmap.type === "avgGreen") {
    // Find if the biggest is between avg and min or avg and max
    const distanceToAvg = max - avg >= avg - min ? max - avg : avg - min
    const colorDensity = distanceToAvg === 0 ? 0 : (value - avg) / distanceToAvg
    switch (true) {
      case colorDensity >= 0:
        return `rgba(0, 100, 0, ${colorDensity})`
      case colorDensity < 0:
        return `rgba(100, 0, 0, ${Math.abs(colorDensity)})`
      default:
        throw new Error("Problem with coloring (avg).")
    }
  }
  if (heatmap.type === "avgRed") {
    // Find if the biggest is between avg and min or avg and max
    const distanceToAvg = max - avg >= avg - min ? max - avg : avg - min
    const colorDensity = distanceToAvg === 0 ? 0 : (value - avg) / distanceToAvg
    switch (true) {
      case colorDensity >= 0:
        return `rgba(100, 0, 0, ${colorDensity})`
      case colorDensity < 0:
        return `rgba(0, 100, 0, ${Math.abs(colorDensity)})`
      default:
        throw new Error("Problem with coloring (avg).")
    }
  }
  if (heatmap.type === "avgBlue") {
    const distanceToAvg = max - avg >= avg - min ? max - avg : avg - min
    const colorDensity = distanceToAvg === 0 ? 0 : (value - avg) / distanceToAvg
    switch (true) {
      case colorDensity >= 0:
        return `rgba(100, 0, 0, ${colorDensity})`
      case colorDensity < 0:
        return `rgba(0, 0, 100, ${Math.abs(colorDensity)})`
      default:
        throw new Error("Problem with coloring (avg).")
    }
  }
  if (heatmap.type === "zeroGreen") {
    const distanceToZero = Math.abs(max) >= Math.abs(min) ? Math.abs(max) : Math.abs(min)
    const colorDensity = distanceToZero === 0 ? 0 : value / distanceToZero
    switch (true) {
      case value >= 0:
        return `rgba(0, 100, 0, ${colorDensity})`
      case value < 0:
        return `rgba(100, 0, 0, ${Math.abs(colorDensity)})`
      default:
        throw new Error("Problem with coloring (zero).")
    }
  }
  if (heatmap.type === "zeroRed") {
    const distanceToZero = Math.abs(max) >= Math.abs(min) ? Math.abs(max) : Math.abs(min)
    const colorDensity = distanceToZero === 0 ? 0 : value / distanceToZero
    switch (true) {
      case value >= 0:
        return `rgba(100, 0, 0, ${colorDensity})`
      case value < 0:
        return `rgba(0, 100, 0, ${Math.abs(colorDensity)})`
      default:
        throw new Error("Problem with coloring (zero).")
    }
  }
  if (heatmap.type === "zeroBlue") {
    const distanceToZero = Math.abs(max) >= Math.abs(min) ? Math.abs(max) : Math.abs(min)
    const colorDensity = distanceToZero === 0 ? 0 : value / distanceToZero
    switch (true) {
      case value >= 0:
        return `rgba(100, 0, 0, ${colorDensity})`
      case value < 0:
        return `rgba(0, 0, 100, ${Math.abs(colorDensity)})`
      default:
        throw new Error("Problem with coloring (zero).")
    }
  }
}

export const formatCell = params => {
  const {
    value,
    node: { id },
  } = params
  const regex = /(\w+)/g
  const rowId = regex.exec(id)[0]
  if (value === 0) return { textAlign: "right" } // need this 'if' because 0 return no value
  if (value) {
    if (typeof value !== "number") return { textAlign: "right" }

    const {
      colDef: {
        heatmap: {
          all: { min, max, avg },
        },
        frontOpts: { heatmap },
      },
    } = params

    if (!heatmap)
      return {
        textAlign: "right",
      }

    if (heatmap.allRows === true)
      return {
        textAlign: "right",
        background: getBackground({ value, min, max, avg, heatmap }),
      }

    const rowMin = params.colDef.heatmap[rowId].min
    const rowMax = params.colDef.heatmap[rowId].max
    const rowAvg = params.colDef.heatmap[rowId].avg
    return {
      textAlign: "right",
      background: getBackground({
        value,
        min: rowMin,
        max: rowMax,
        avg: rowAvg,
        heatmap,
      }),
    }
  }
}

export const valueFormatter = params => {
  const value = params.value
  const desc = params.data.desc

  if (desc === "Actions" && value) return value
  if (value === "0E-8") return 0

  const regex = /([a-z])|(\d+-\d+-\d+)/gi // test if value includes at least one letter or if value is a date
  const isString = regex.test(value)
  const formatedValues = isString
    ? value
    : params.colDef.frontOpts.decimals
      ? newFormatNumber(value, {
          minDigit: params.colDef.frontOpts.decimals.min,
          maxDigit: params.colDef.frontOpts.decimals.max,
          pct: params.colDef.frontOpts.pct,
        })
      : newFormatNumber(value, {
          minDigit: 0,
          maxDigit: 2,
          pct: false,
        })
  return formatedValues
}

export const shapeColTitles = (colTitles, colsDisplayed, fieldList, factorList, target, method) => {
  const width = col => parseInt(colsDisplayed[R.indexOf(col, colTitles)].frontOpts.width)

  return R.map(col => {
    return R.compose(
      R.dissoc("cellRenderer"), // cellRenderer is not used in MSC for now
      R.assoc(
        "headerName",
        getColTitle(colsDisplayed[R.indexOf(col, colTitles)], fieldList, factorList),
      ),
      R.assoc("cellStyle", formatCell),
      R.assoc("cellClass", cellClass),
      colsDisplayed[R.indexOf(col, colTitles)].kind === "separationCol" //apply only to kind=col because it's the only kind without children
        ? R.assoc("width", width(col) ? width(col) : 50)
        : R.identity,
      R.assoc("frontOpts", colsDisplayed[R.indexOf(col, colTitles)].frontOpts),
      R.mapObjIndexed((el, key, object) => {
        if (key === "children")
          return R.compose(
            R.map(child =>
              child.field === target && method === "asc"
                ? R.assoc("headerClass", [child.headerClass, "sct-header-sort-asc"], child)
                : child.field === target && method === "desc"
                  ? R.assoc("headerClass", [child.headerClass, "sct-header-sort-desc"], child)
                  : child,
            ),
            R.map(child => R.assoc("width", width(col) ? width(col) : 120, child)),
            R.map(child => R.assoc("valueFormatter", valueFormatter, child)),
            R.map(child => R.assoc("cellStyle", formatCell, child)),
            R.map(child => R.assoc("cellClass", cellClass, child)),
            R.map(child => R.assoc("kind", colsDisplayed[R.indexOf(col, colTitles)].kind, child)),
            R.map(child =>
              R.assoc("frontOpts", colsDisplayed[R.indexOf(col, colTitles)].frontOpts, child),
            ),
          )(el)
        return el
      }),
    )(col)
  }, colTitles)
}
